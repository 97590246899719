import './FeaturesSection.css'
import { imgVersion } from '../common/Utils';

export const FeaturesSection = () => (
  <div>
    <section className="features-section">
      <div className='container'>
        <h2 className="jobs-support-title">支持多岗位模拟/实战面试</h2>
        <img src={`${process.env.PUBLIC_URL}/assets/images/all_jobs.png?v=${imgVersion}`} alt="支持多岗位面试" className="jobs-support-image" />
        <div className="jobs-support-desc-wrapper">
          <p className="jobs-support-demo-desc">操作简单，不用担心面试时手忙脚乱</p>
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/all_jobs_logo.png?v=${imgVersion}`}
            alt="offerji"
            className="jobs-support-logo-image"
          />
        </div>
        <h2 className="resume-title">AI优化简历内容</h2>
        <p className="resume-desc">让简历更具竞争力！</p>
        <img src={`${process.env.PUBLIC_URL}/assets/images/resume_pc.png?v=${imgVersion}`} alt="AI优化简历内容" className="resume-image" />
      </div>
    </section>
  </div>
);