import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import HTMLPage from './htmlPage';
import { TopBar } from './ui/TopBar';
import { Header } from './ui/Header';
import { HeaderV2 } from './ui/HeaderV2';
import { HeaderV2Mobile } from './ui/HeaderV2Mobile';
import { FeaturesSection } from './ui/FeaturesSection';
import { FeaturesSectionMobile } from './ui/FeaturesSectionMobile';
import { MeetingSection } from './ui/MeetingSection';
import { AdvantagesSection } from './ui/AdvantagesSection';
import { FQASection } from './ui/FQASection';
import { AboutSection } from './ui/AboutSection';
import { currentDomain, isWindows } from './common/Utils';

const hostname = window.location.hostname;
const isDomain = /^[a-zA-Z0-9.-]+$/.test(hostname) && !/^\d{1,3}(\.\d{1,3}){3}$/.test(hostname);
if (isDomain) {
  if (window.location.protocol === 'http:') {
    window.location.href = window.location.href.replace('http:', 'https:')
  }
  if (/offerji\.cn$/.test(window.location.hostname)) {
    window.location.href = window.location.href.replace(/offerji\.cn/g, 'offerchick.cn');
  }
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/terms" element={<TermsPage />} />
        <Route path="/privacy" element={<PrivacyPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
}

/// HomePage 
///

function HomePage() {
  const [macDownloadLink, setMacDownloadLink] = useState('');
  const [windowsDownloadLink, setWindowsDownloadLink] = useState('');

  const [contactAllItems, setContactAllItems] = useState([]);
  const [contactSomeItems, setContactSomeItems] = useState([]);

  const [isMobileSize, setIsMobileSize] = useState(window.innerWidth <= 768);

  async function requestOnlineConfigs() {
    try {
      let baseUrl;
      const DEBUG = false;
      if (currentDomain.includes('192.168') && DEBUG) {
        baseUrl = 'http://192.168.0.100:22004'
      }
      else {
        baseUrl = 'https://api.offerchick.cn'
      }
      const response = await fetch(`${baseUrl}/v1/clientapi/getconf?client_version=0&config_version=0&os_type=web`);
      const data = await response.json();
      if (data.code === 0) {
        const configs = data.data.configs;
        const macConfig = configs.find(config => config.key === 'latest_mac_url');
        const windowsConfig = configs.find(config => config.key === 'latest_pc_url');
        const contactsAllConfig = configs.find(config => config.key === 'contacts_all');
        const contactsSomeConfig = configs.find(config => config.key === 'contacts_some');
        if (macConfig) {
          setMacDownloadLink(macConfig.value);
        }
        if (windowsConfig) {
          setWindowsDownloadLink(windowsConfig.value);
        }
        if (contactsAllConfig) {
          try {
            const contactAllItems = JSON.parse(contactsAllConfig.value);
            setContactAllItems(contactAllItems);
          } catch (error) {
            console.error("Failed to parse contactsAllConfig:", error, contactsAllConfig.value);
          }
        }
        if (contactsSomeConfig) {
          try {
            const contactSomeItems = JSON.parse(contactsSomeConfig.value);
            setContactSomeItems(contactSomeItems);
          } catch (error) {
            console.error("Failed to parse contactsSomeConfig:", error, contactsSomeConfig.value);
          }
        }
      }
    } catch (error) {
      console.error('Failed to fetch download links:', error);
    }
  }

  useEffect(() => {
    requestOnlineConfigs();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileSize(window.innerWidth <= 768); 
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="app">
      <TopBar
        macDownloadLink={macDownloadLink}
        windowsDownloadLink={windowsDownloadLink}
        contactItems={contactSomeItems}
        isMobileSize={isMobileSize}
      />
      {/* <Header
        macDownloadLink={macDownloadLink}
        windowsDownloadLink={windowsDownloadLink}
      /> */}
      {isMobileSize ? 
        <HeaderV2Mobile /> : 
        <HeaderV2 
          macDownloadLink={macDownloadLink}
          windowsDownloadLink={windowsDownloadLink}
        />
      }
      {isMobileSize ? 
        <FeaturesSectionMobile /> : 
        <FeaturesSection />
      }
      
      <MeetingSection/>
      <AdvantagesSection/>
      <FQASection/>
      <AboutSection
        contactItems={contactAllItems}
      />
    </div>
  );
}

function TermsPage() {
  return <HTMLPage url="/pages/terms.html" />;
}

function PrivacyPage() {
  return <HTMLPage url="/pages/privacy.html" />;
}

function NotFoundPage() {
  return <HTMLPage url="/pages/404.html" />;
}

export default App;
