import './AdvantagesSection.css';
import { imgVersion } from '../common/Utils';

export const AdvantagesSection = () => (
  <section className="advantages-section">
    <div className='container'>
      <h1 className="advantages-title">优势</h1>
      <div className="advantages-image-container advantage-1">
        <img src={`${process.env.PUBLIC_URL}/assets/images/dual.png?v=${imgVersion}`} alt="双路语音实时采集和识别" className="advantages-image" />
        <p className="advantages-text">
          <span className="advantages-dual-part-1">双路语音</span>
          <span className="advantages-dual-part-2">实时采集和识别</span>
        </p>
      </div>
      <div className="advantages-image-container advantage-2">
        <img src={`${process.env.PUBLIC_URL}/assets/images/ai.png?v=${imgVersion}`} alt="最先进的AI大模型全程护航" className="advantages-image" />
        <p>最先进的AI大模型全程护航</p>
      </div>
      <div className="advantages-image-container advantage-3">
        <img src={`${process.env.PUBLIC_URL}/assets/images/meeting.png?v=${imgVersion}`} alt="适配主流会议软件" className="advantages-image" />
        <p>适配主流会议软件</p>
      </div>
      <div className="advantages-image-container advantage-4">
        <img src={`${process.env.PUBLIC_URL}/assets/images/jobs.png?v=${imgVersion}`} alt="全岗位支持" className="advantages-image" />
        <p>全岗位支持</p>
      </div>
      <div className="advantages-image-container advantage-5">
        <img src={`${process.env.PUBLIC_URL}/assets/images/lang.png?v=${imgVersion}`} alt="全岗位支持" className="advantages-image" />
        <p className="advantages-text">
          <span className="advantages-lang-part-1">支持实时中英文面试</span>
          <span className="advantages-lang-part-2">无需预设切换</span>
        </p>
      </div>
      <div className="advantages-image-container advantage-6">
        <img src={`${process.env.PUBLIC_URL}/assets/images/archive.png?v=${imgVersion}`} alt="个人面试记录档案" className="advantages-image" />
        <p>个人面试记录档案</p>
      </div>
    </div>
  </section>
);
