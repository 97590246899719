import React, { useEffect } from 'react';
import "./HeaderV2Mobile.css";
import { imgVersion, handleDownload } from '../common/Utils';

export const HeaderV2Mobile = () => {
  return (
    <header className="header-v2-mobile">
      <div className="container">
        <img
          src={`${process.env.PUBLIC_URL}/assets/images/header_wing.png?v=${imgVersion}`}
          alt="header-wing"
          className="header-v2-mobile-wing"
        />
        <p className='header-v2-mobile-title'>Offer鸡</p>
        <p className='header-v2-mobile-desc'>AI面试演讲提词器<br />轻松拿Offer</p>
        
        <div className="header-v2-video-container-mobile">
          {/* <img 
            src={`${process.env.PUBLIC_URL}/assets/images/video_bg.png?v=${imgVersion}`}
            alt="video-bg"
            className="header-v2-video-bg-mobile"
          /> */}
          <div className="header-v2-video-player-mobile">
            <iframe
              src="https://player.bilibili.com/player.html?bvid=BV1euSMYCEMJ&quality=80"
              allowFullScreen
              title="header-v2-video-player-mobile"
            />
          </div>
        </div>

        <img
          src={`${process.env.PUBLIC_URL}/assets/images/reward_mobile.png?v=${imgVersion}`}
          alt="下载注册即送免费时长"
          className="header-v2-mobile-reward-banner"
        />
        <button
          className="download-button header-v2-mobile-download-button"
          id="mobile-download-button"
          onClick={() => handleDownload()}
        >
          下载使用
        </button>
      </div>
    </header>
  );
}