
import './FQASection.css';

export const FQASection = () => (
  <section className="faq-section">
    <div className='container'>
      <h1 className="faq-title">常见问题</h1>
      
      <div className="faq-item">
        <h2 className="faq-question">Offer鸡有哪些功能？</h2>
        <p className="faq-answer">Offer鸡可以帮助你检查简历问题，帮助你模拟面试，帮助你实战面试。本质上都是起到辅助的作用，让你的面试更自信，回答更流畅，Offer从四面八方来！</p>
      </div>

      <div className="faq-item">
        <h2 className="faq-question">Offer鸡适用哪些岗位？</h2>
        <p className="faq-answer">适用于所有岗位，特别擅长各类知识和技术性强的岗位，针对性地帮助用户在面试临场填补知识空缺。</p>
      </div>
      
      <div className="faq-item">
        <h2 className="faq-question">Offer鸡能真正理解并快速回答面试问题吗？</h2>
        <p className="faq-answer">是的，Offer鸡基于最先进的实时语音识别和AI大模型技术，能实时了解当下用户的面试情况，生成个性化的回答。随着问答次数的增加，Offer鸡会越来了解用户经历，回答会越来越贴合用户的实际情况。</p>
      </div>
      
      <div className="faq-item">
        <h2 className="faq-question">Offer鸡在面试中使用是否会影响面试？</h2>
        <p className="faq-answer">Offer鸡主要针对线上面试，在面试中给用户提供回答的引导，包括回答的纲领和细节。在实际面试中引导话术和思路，让面试者有更多的底气和自信，也可以积累更多的面试经验和技巧。不过多影响当前面试者本身的行为和思路。面试结束后用户可以通过Offer鸡提供的面试记录进行复盘和总结。</p>
      </div>
    </div>
  </section>
);