import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export const imgVersion = 20241009

export const isMac = /Macintosh|Mac OS X/i.test(navigator.userAgent);
export const isWindows = /Windows NT/i.test(navigator.userAgent);
export const isMobile = /iPhone|iPad|iPod|Android|HarmonyOS/i.test(navigator.userAgent);

export const currentDomain = window.location.hostname || 'offerchick.cn';

export const swal = withReactContent(Swal);

export function isWeChatBrowser() {
  const ua = window.navigator.userAgent.toLowerCase();
  return ua.includes('micromessenger');
}

export const showToast = (text) => {
    const toast = document.createElement('div');
  toast.textContent = text;
  
  toast.style.position = 'fixed';
  toast.style.bottom = '20px';
  toast.style.left = '50%';
  toast.style.transform = 'translateX(-50%)';
  toast.style.backgroundColor = 'rgba(0, 0, 0, 0.8)';
  toast.style.color = '#ffffff';
  toast.style.padding = '10px 20px';
  toast.style.borderRadius = '5px';
  toast.style.fontSize = '16px';
  toast.style.zIndex = '1000';
  toast.style.opacity = '0';
  toast.style.transition = 'opacity 0.3s ease';

  document.body.appendChild(toast);

  setTimeout(() => {
    toast.style.opacity = '1';
  }, 100); 

  setTimeout(() => {
    toast.style.opacity = '0';
    setTimeout(() => {
      document.body.removeChild(toast);
    }, 300);
  }, 3000);
}; 

export const handleDownload = (downloadLink = null) => {
  if (isMobile) {
    handleCopyToDownloadClick('请复制以下链接在电脑端浏览器打开');
  }
  else if (isWeChatBrowser()) {
    handleCopyToDownloadClick('请复制以下链接在系统浏览器中打开');
  }
  else if (downloadLink) {
    downloadApp(downloadLink);
  }
  else {
    console.error('Failed to download, empty link url');
  }
};

export const handleCopyToDownloadClick = (msg) => {
  const copy_content = `https://${currentDomain}`;
  swal.fire({
    html: `
      <div style="text-align: center;">
        <p style="color: #470B14; font-size: 16px; margin-bottom: 20px;">
          ${msg}
        </p>
        <a href="${copy_content}" style="font-size: 16px; color: #000000; text-decoration: underline;" target="_blank">
          ${copy_content}
        </a>
      </div>
    `,
    showCancelButton: false,
    showConfirmButton: true,
    confirmButtonText: '复制并关闭',
    customClass: {
      confirmButton: 'alert-button-single'
    }
  }).then((result) => {
    if (result.isConfirmed) {
      copyText(copy_content);
    }
  });
}

export const downloadApp = (downloadLink) => {
  const link = document.createElement('a');
  link.href = downloadLink;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export const copyText = (text) => {
  try {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(text)
        .then(() => {
          showToast('复制成功');
        })
        .catch((err) => {
          console.error('navigator.clipboard.writeText failed 1:', err);
          fallbackCopyText(text);
        });
    }
    else {
      throw new Error('clipboard API is unsupported');
    }
  } catch (err) {
    console.error('navigator.clipboard.writeText failed 2:', err);
    fallbackCopyText(text)
  }
}

export const fallbackCopyText = (text) => {
  const textArea = document.createElement("textarea");
  textArea.value = text;

  // Prevent the textarea from affecting page layout
  textArea.style.position = "fixed";
  textArea.style.top = 0;
  textArea.style.left = 0;
  textArea.style.width = "2em";
  textArea.style.height = "2em";
  textArea.style.padding = 0;
  textArea.style.border = "none";
  textArea.style.outline = "none";
  textArea.style.boxShadow = "none";
  textArea.style.background = "transparent";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  var successful = false;
  try {
    successful = document.execCommand('copy');
  } catch (err) {
    console.error('Fallback copy failed', err);
  }

  if (successful) {
    showToast('复制成功');
  } else {
    showToast('复制失败');
  }

  document.body.removeChild(textArea);
}; 
