
import './MeetingSection.css';
import { imgVersion } from '../common/Utils';

export const MeetingSection = () => (
  <section className="meeting-section">
    <div className='container'>
      <h2 className="meeting-support-title">支持常见的远程面试平台</h2>
      <div className="meeting-apps-image-container">
        <img
          src={`${process.env.PUBLIC_URL}/assets/images/meetings.png?v=${imgVersion}`}
          alt="支持常见的远程面试平台"
          className="meeting-apps-image-pc"
        />
        <img
          src={`${process.env.PUBLIC_URL}/assets/images/meetings_mobile.png?v=${imgVersion}`}
          alt="支持常见的远程面试平台"
          className="meeting-apps-image-mobile"
        />
      </div>
    </div>
  </section>
);
