import './FeaturesSectionMobile.css'
import { imgVersion } from '../common/Utils';

export const FeaturesSectionMobile = () => (
  <div>
    <section className="jobs-support-mobile-container">
      <h2 className="jobs-support-mobile-title">支持多岗位模拟/实战面试</h2>
      <img src={`${process.env.PUBLIC_URL}/assets/images/all_jobs_mobile.png?v=${imgVersion}`} alt="支持多岗位面试" className="jobs-support-mobile-image" />
    </section>
    <section className="resume-mobile-container">
      <p className="resume-mobile-desc">AI优化简历内容<br />让简历更具竞争力！</p>
      <img src={`${process.env.PUBLIC_URL}/assets/images/resume_mobile.png?v=${imgVersion}`} alt="AI优化简历内容" className="features-mobile-image-left" />
      <p className="features-mobile-desc">操作简单，不用担心手忙脚乱~</p>
      <img src={`${process.env.PUBLIC_URL}/assets/images/demo_mobile_left.png?v=${imgVersion}`} alt="操作简单" className="features-mobile-image-left features-mobile-image-left-mask " />
      <img src={`${process.env.PUBLIC_URL}/assets/images/demo_mobile_right.png?v=${imgVersion}`} alt="操作简单" className="features-mobile-image-right features-mobile-image-right-mask " />
    </section>
  </div>
);