import React, { useRef, useEffect, useState } from 'react';
import './TopBar.css';
import './Common.css';
import { imgVersion, isMobile, isMac, isWeChatBrowser, handleDownload } from '../common/Utils';

export const TopBar = ({ macDownloadLink, windowsDownloadLink, contactItems, isMobileSize }) => {
  const [showContactPopup, setShowContactPopup] = useState(false);
  const [showDownloadHelpPopup, setShowDownloadHelpPopup] = useState(false);
  const contactLinkRef = useRef(null);

  const filteredContactItems = isWeChatBrowser()
    ? contactItems.filter((item) => !item.desc.includes('QQ'))
    : contactItems;

  useEffect(() => {
    const handleResize = () => {
      setShowContactPopup(false);
      setShowDownloadHelpPopup(false);
    };
    window.addEventListener("resize", handleResize); 

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <header className="topbar">
      <div className="container">
        <div className="topbar-left">
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/${isMobile ? "logo_text_mobile" : "logo_text"}.png?v=${imgVersion}`}
            alt="offerji text logo"
            className='topbar-logo'
          />
        </div>
        <div className="topbar-right">
          <a
            className="link"
            id="topbar-contact"
            ref={contactLinkRef}
            onClick={() => setShowContactPopup(true)}
          >
            加群领福利
          </a>
          {(!isMac && !isMobileSize) && (
            <a 
              className="link" 
              id="topbar-download-help"
              onClick={() => setShowDownloadHelpPopup(true)}
            >
              下载问题
            </a>
          )}
          {!isMobileSize && (
            <button
              className="topbar-download-button download-button"
              id="topbar-download-button"
              onClick={() => handleDownload(isMac ? macDownloadLink : windowsDownloadLink)}
            >
              立即下载
            </button>
          )}
        </div>
      </div>
      {showContactPopup && (
        <ContactPopup
          contactItems={filteredContactItems}
          onClose={() => setShowContactPopup(false)}
          buttonRef={contactLinkRef} 
          isMobileSize={isMobileSize}
        />
      )}
      {showDownloadHelpPopup && (
        <DownloadHelpPopup
          onClose={() => setShowDownloadHelpPopup(false)}
        />
      )}
    </header>
  );
};


const ContactPopup = ({ contactItems, onClose, buttonRef, isMobileSize }) => {
  const popupRef = useRef(null);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target) &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsVisible(false);
        setTimeout(onClose, 200);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose, buttonRef]);

  useEffect(() => {
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      const popupRect = popupRef.current?.getBoundingClientRect() || { width: 0 };
      if (isMobileSize) {
        setPosition({
          top: rect.bottom + 30,
          left: rect.right - popupRect.width,
        });
      } else {
        setPosition({
          top: rect.bottom + 20,
          left: rect.left - (popupRect.width - rect.width) / 2,
        });
      }
      setIsVisible(true);
    }
  }, [buttonRef, isMobileSize]);

  return (
    <div
      className="contact-popup-overlay"
      style={{ top: `${position.top}px`, left: `${position.left}px` }}
    >
      <div
        className={`contact-popup-container ${isVisible ? "show" : "hide"}`}
        ref={popupRef}
      >
        <div className="contact-popup-content">
          {contactItems.map((item, index) => (
            <div className="contact-popup-item" key={index}>
              <h3>{item.desc}</h3>
              <div className="contact-popup-image">
                {item.qrcode ? (
                  <img src={item.qrcode} alt={item.desc} />
                ) : (
                  <div className="contact-popup-image-placeholder"></div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};


const DownloadHelpPopup = ({onClose}) => {
  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target)
      ) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  return (
    <div className="download-popup-overlay">
      <div className="download-popup-container" ref={popupRef}>
        <img 
          src={`${process.env.PUBLIC_URL}/assets/images/download_help.jpg?v=${imgVersion}`} 
          alt="下载问题" 
          className="download-popup-image" />
        <button 
          className="download-popup-close-button" 
          onClick={onClose}>
          &times;
        </button>
      </div>
    </div>
  );
};
